import {
  faArrowLeft,
  faClose,
  faEllipsisH,
  faTimes,
  faChevronDown,
  faSearch,
  faSort,
  faSortUp,
  faSortDown,
  faNoteSticky,
  faRotate,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faArrowLeft as faArrowLeftSolid,
  faXmark,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(
  faRotate,
  faArrowLeft,
  faPlus,
  faClose,
  faEllipsisH,
  faTimes,
  faChevronDown,
  faSearch,
  faSortUp,
  faSortDown,
  faSort,
  faNoteSticky,
  faArrowLeftSolid,
  faXmark
);
