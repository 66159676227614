<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
import { onSetupEvent, sendExitEvent } from "../../parent-interface";
import "@/styles/global.sass";
import "@/styles/type.sass";
import "@/styles/json-form.sass";
import "@wegift/two-components/dist/style.css";
import "../../icons";
import {
  OpenAPI as BrandApprovalApplicationServiceAPI,
  BrandApprovalApplicationService,
  BrandApprovalApplicationResponse,
  ApiError as BrandApprovalServiceApiError,
} from "@/api/brand-approval-application-service";
import { OpenAPI as ScionAPI } from "@/api/scion";
import { OpenAPI as BrandApprovalAPI } from "@/api/brand-approval-service";
import {
  STEP_FORM_00,
  STEP_FORM_01,
  STEP_FORM_02,
  STEP_FORM_03,
} from "../../constants";
import BackButton from "@/components/BackButton.vue";
import { useGlobalStore } from "../../store";

BrandApprovalApplicationServiceAPI.BASE =
  import.meta.env.VITE_BRAND_APPROVAL_APPLICATION_SERVICE_BASE_URL;
ScionAPI.BASE = import.meta.env.VITE_SCION_BASE_URL;
BrandApprovalAPI.BASE = import.meta.env.VITE_BRAND_APPROVAL_SERVICE_BASE_URL;

const globalStore = useGlobalStore();

const router = window.globalRouter || useRouter();
const formData = ref<BrandApprovalApplicationResponse | null>(null);
const step = ref(1);
const totalSteps = ref(4);
const isConnect = ref(false);

const getAnswers = async () => {
  if (globalStore.customerId) {
    try {
      const response =
        await BrandApprovalApplicationService.getBrandApprovalApplication(
          globalStore.customerId
        );
      formData.value = response;
      totalSteps.value = totalSteps.value - 1;
      router.push({
        name: STEP_FORM_01,
      });
    } catch (error) {
      if (
        error instanceof BrandApprovalServiceApiError &&
        error.status === 404
      ) {
        router.push({ name: STEP_FORM_00 });
      } else {
        throw error;
      }
    }
  }
};

const handleNextStep = (currentStep: string) => {
  if (currentStep === STEP_FORM_00) {
    router.push({ name: STEP_FORM_01 });
  } else if (currentStep === STEP_FORM_01) {
    router.push({ name: STEP_FORM_02 });
  } else if (currentStep === STEP_FORM_02) {
    router.push({ name: STEP_FORM_03 });
  }
  step.value = step.value + 1;
};

const handleBackButton = () => {
  if (step.value === 1) {
    if (isConnect.value) {
      sendExitEvent();
    }
    router.push({ name: "home" });
    return;
  }
  step.value = step.value - 1;
  router.back();
};

const updateAnswersForm = (data: any) => {
  formData.value = {
    customerId: globalStore.customerId as string,
    dateSubmitted: new Date().toTimeString(),
    answers: { ...data, contactEmails: [data.contactEmail] },
  };
};

if (globalStore.brandApprovalAccessToken && globalStore.customerId) {
  BrandApprovalApplicationServiceAPI.TOKEN = BrandApprovalAPI.TOKEN =
    globalStore.brandApprovalAccessToken as string;
  ScionAPI.TOKEN = globalStore.token as string;
  getAnswers();
}

onSetupEvent(async (event) => {
  globalStore.customerId = event.data.customerId as string;
  globalStore.customerName = event.data.customerName;
  BrandApprovalApplicationServiceAPI.TOKEN = event.data.accessToken;
  ScionAPI.TOKEN = BrandApprovalAPI.TOKEN = event.data.accessTokenBAS;
  isConnect.value = true;
  getAnswers();
});
</script>

<template>
  <div
    class="container mx-auto flex h-screen items-center justify-center"
    v-if="!globalStore.customerId"
  >
    Please select customer
  </div>
  <div class="baf-container bg-grey-50" v-else>
    <div class="container mx-auto max-w-3xl py-4">
      <BackButton
        @click="handleBackButton"
        data-testid="back-button-main-header"
      >
        <h1 class="text-3xl" data-testid="main-header-text">
          Manual approval form (step {{ step }}/{{ totalSteps }})
        </h1>
      </BackButton>
      <main class="rounded-lg bg-white p-6" data-testid="main-form-container">
        <router-view
          :customer-id="globalStore.customerId"
          :customer-name="globalStore.customerName"
          :is-connect="isConnect"
          :form-data="formData"
          :total-steps="totalSteps"
          @handle-next-step="handleNextStep"
          @update-answers-form="updateAnswersForm"
        ></router-view>
      </main>
    </div>
  </div>
</template>
